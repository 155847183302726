import { Theme } from './themes'

const textLight = {
  primary: 'rgba(52, 49, 76, 1)',
  secondary: 'rgba(52, 49, 76, 0.54)',
  disabled: 'rgba(52, 49, 76, 0.38)',
  hint: 'rgba(52, 49, 76, 0.38)',
}

const errorColor = {
  main: '#FF3D57',
}

const themeColors = {
  topbar: {
    palette: {
      type: 'light',
      primary: {
        main: '#ffffff',
        contrastText: textLight.primary,
      },
      secondary: {
        main: '#1976d2',
        contrastText: '#ffffff',
      },
      text: textLight,
    },
  },
  sidebar: {
    palette: {
      type: 'dark',
      primary: {
        main: '#222A45',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#ff9e43',
        contrastText: textLight.primary,
      },
      error: errorColor,
      background: {
        paper: '#222A45',
        default: '#1a2038',
      },
    },
  },
}

const buildThemeForPrimaryColor = (
  primaryColorHex = '#1976d2',
  primaryColorDark = 'rgba(52, 49, 76, 1)',
  primaryColorDarkContrast = '#fff'
) => ({
  palette: {
    type: 'light',
    primary: {
      main: primaryColorHex,
      contrastText: '#ffffff',
    },
    primaryDark: {
      main: primaryColorDark,
      contrastText: primaryColorDarkContrast,
    },
    secondary: {
      main: '#AAA',
      contrastText: textLight.primary,
    },
    error: errorColor,
    text: textLight,
  },
})

themeColors[Theme.AMSM] = buildThemeForPrimaryColor()
themeColors[Theme.CIC] = buildThemeForPrimaryColor()
themeColors[Theme.CLAB] = buildThemeForPrimaryColor('#6791E4', '#111111')
themeColors[Theme.ACTION] = buildThemeForPrimaryColor()
themeColors[Theme.IGORSANDI] = buildThemeForPrimaryColor('#3a45cc')
themeColors[Theme.GUILHERMELAZARETTI] = buildThemeForPrimaryColor(
  '#151616',
  '#151616'
)
themeColors[Theme.LARISSACIOATO] = buildThemeForPrimaryColor(
  '#d6a800',
  '#111111'
)
themeColors[Theme.BIOACADEMIA] = buildThemeForPrimaryColor('#d0951e', '#0b4824')
themeColors[Theme.DCFIT] = buildThemeForPrimaryColor('#226099')

export { themeColors }

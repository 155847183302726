import React from 'react'
import { FormHelperText, TextField } from '@material-ui/core'
import { appendRequired } from 'app/reusable/utils'

const TextInput = ({
  name,
  value,
  field,
  onChange,
  errors,
  testingClassName,
}) => (
  <TextField
    disabled={field.widget.attrs.disabled}
    multiline={field.widget.input_type === 'textarea'}
    minRows={field.widget.input_type === 'textarea' ? 3 : 1}
    helperText={
      !!(errors || field.help_text) && (
        <FormHelperText className="mb-3">
          {errors ? errors.join(', ') : field.help_text}
        </FormHelperText>
      )
    }
    name={name}
    onChange={onChange}
    error={errors?.length}
    className={testingClassName}
    fullWidth
    variant="outlined"
    label={appendRequired(field.label, field.required)}
    value={value || ''}
    size="small"
    inputProps={{
      maxLength: field.max_length,
    }}
  />
)

export default TextInput

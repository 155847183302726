import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react'
import { TableCell, Card, TextField, Button } from '@material-ui/core'
import { Icon, IconButton } from '@material-ui/core'
import { useHistory } from 'react-router'
import ListTable from 'app/components/Tables/ListTable'
import useDjangoList from 'app/hooks/useDjangoList'
import HttpHandler from 'app/reusable/HttpHandler'
import { toast } from 'react-toastify'
import startCase from 'lodash/startCase'
import { useDismissModal, useModal } from 'app/ducks/modal/hooks'
import { TOAST_FEEDBACK } from 'app/reusable/utils'

import Pagination from '@mui/material/Pagination'
import OpenFileButton from '../OpenFileButton/OpenFileButton'
import UserTypeSpecific from '../UserTypeSpecific/UserTypeSpecific'
import { USER_TYPE } from 'app/ducks/auth/types'
import { Loading } from '../SimpleList/SimpleList'
import { useHasPermission } from 'app/ducks/auth/hooks'

export const ActionButton = ({ id, onClick, iconName, children, ...props }) => (
  <IconButton size="small" className="p-2" id={id} onClick={onClick} {...props}>
    <Icon fontSize="small" id={id}>
      {iconName}
    </Icon>
    {children}
  </IconButton>
)

const DjangoList = forwardRef(
  (
    {
      djangoApp,
      djangoModel,
      fields,
      orderBy,
      customFields,
      emptyStateText = 'Nenhum registro encontrado',
      aditionalActions: AditionalActions,
      headers: customHeaders,
      additionalHeaderActions: AdditionalHeaderActions,
      initialFilters = {},
      onAdd = null,
      showAdd = true,
      filters: FiltersComponent = undefined,
    },
    ref
  ) => {
    const history = useHistory()
    const showModal = useModal()
    const dismissModal = useDismissModal()

    const hasAddPermission = useHasPermission(djangoModel, 'add')
    const hasChangePermission = useHasPermission(djangoModel, 'change')
    const hasDeletePermission = useHasPermission(djangoModel, 'delete')

    const {
      data,
      header,
      paginator,
      title,
      textFilter,
      filters,
      isLoading,
      setFilters,
      setTextFilter,
      refresh,
      setPage,
    } = useDjangoList(djangoApp, djangoModel, {
      order_by: orderBy,
      ...initialFilters,
    })

    useImperativeHandle(ref, () => ({ reload: refresh, setFilters }))

    const formPath = useMemo(() => `/${djangoModel}/form`, [djangoModel])

    const confirmDelete = useCallback(
      (id) => {
        toast
          .promise(
            HttpHandler.delete(djangoApp, djangoModel, id),
            TOAST_FEEDBACK.DELETE
          )
          .then(() => {
            refresh()
            dismissModal()
          })
      },
      [refresh, djangoApp, djangoModel, dismissModal]
    )

    const handleDelete = ({ target: { id } }) =>
      showModal({
        title: 'Exclusão',
        onConfirm: () => confirmDelete(id),
        children: 'Tem certeza que deseja excluir o usuário?',
      })

    const handleUpdate = useCallback(
      ({ target: { id } }) => history.push(`${formPath}?id=${id}`),
      [history, formPath]
    )

    return (
      <div className="m-sm-30 mt-6">
        <Card className="p-4 mb-6">
          <div className="flex-center-v flex-between w-full mb-3">
            <div>
              <h4>{startCase(title?.toLowerCase())}</h4>
            </div>
            <div>
              {AdditionalHeaderActions && <AdditionalHeaderActions />}
              <UserTypeSpecific
                type={[
                  USER_TYPE.ADMINISTRATOR,
                  USER_TYPE.MANAGER,
                  USER_TYPE.COLLABORATOR,
                ]}
              >
                {!!hasAddPermission && showAdd && (
                  <ActionButton
                    onClick={onAdd || (() => history.push(formPath))}
                    iconName="add"
                    title="Adicionar"
                  />
                )}
              </UserTypeSpecific>
            </div>
          </div>

          <div className="flex">
            <TextField
              value={textFilter}
              type="text"
              className="w-full max-w-300 text-center"
              placeholder="Digite um termo para pesquisar"
              onChange={(e) => {
                const value = e.target.value
                setTextFilter(value)
              }}
            />
            {!!FiltersComponent && (
              <>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  className="ml-3"
                  onClick={() =>
                    showModal({
                      title: 'Filtros',
                      children: (
                        <FiltersComponent
                          filters={filters}
                          setFilters={setFilters}
                        />
                      ),
                    })
                  }
                >
                  Ver filtros
                </Button>
                {Object.keys(filters).filter(
                  (k) => !['order_by', 'paginate_by'].includes(k)
                ).length > 0 && (
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    className="ml-3"
                    onClick={() => setFilters({})}
                  >
                    Limpar filtros
                  </Button>
                )}
              </>
            )}
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <ListTable
                data={data}
                striped
                fields={fields}
                headers={{ ...(header || {}), ...(customHeaders || {}) }}
                emptyStateText={emptyStateText}
                customCells={{
                  file: ({ file }) => (
                    <TableCell className="text-center">
                      <OpenFileButton file={file} />
                    </TableCell>
                  ),
                  actions: (row) => (
                    <TableCell className="text-right">
                      {AditionalActions && <AditionalActions {...row} />}
                      {/* TODO: Check permissions */}
                      <UserTypeSpecific
                        type={[
                          USER_TYPE.ADMINISTRATOR,
                          USER_TYPE.MANAGER,
                          USER_TYPE.COLLABORATOR,
                        ]}
                      >
                        {!!hasChangePermission && (
                          <ActionButton
                            id={row.id}
                            iconName="create"
                            title="Editar"
                            onClick={handleUpdate}
                          />
                        )}
                        {!!hasDeletePermission && (
                          <ActionButton
                            id={row.id}
                            iconName="delete"
                            title="Remover"
                            onClick={handleDelete}
                          />
                        )}
                      </UserTypeSpecific>
                    </TableCell>
                  ),
                  ...(customFields || {}),
                }}
                customHeaderCells={{
                  actions: () => (
                    <TableCell
                      style={{ paddingRight: '1rem' }}
                      className="text-right"
                    >
                      Ações
                    </TableCell>
                  ),
                }}
              />
              {paginator?.last_page_number > 1 && (
                <Pagination
                  boundaryCount={3}
                  page={paginator.current_page_number}
                  count={paginator.last_page_number}
                  onChange={(_, page) => setPage(page)}
                  className="mt-5"
                />
              )}
            </>
          )}
        </Card>
      </div>
    )
  }
)

export default DjangoList

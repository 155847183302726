import React from 'react'
import { Switch, FormControlLabel, FormHelperText } from '@material-ui/core'

const SwitchInput = ({ name, value, field, onRawChange }) => (
  <>
    <FormControlLabel
      style={{ height: '100%' }}
      label={field.label}
      control={
        <Switch
          color="primary"
          checked={value}
          name={name}
          onChange={(e) => onRawChange(name, e.target.checked)}
        />
      }
    />

    {!!field.help_text && (
      <FormHelperText className="mb-4">{field.help_text}</FormHelperText>
    )}
  </>
)

export default SwitchInput

import { red } from '@material-ui/core/colors'
import { USER_TYPE } from 'app/ducks/auth/types'
import { Theme } from './themes'

const shadows = [
  'none',
  '0px 2px 1px -1px rgba(0, 0, 0, 0.06),0px 1px 1px 0px rgba(0, 0, 0, 0.042),0px 1px 3px 0px rgba(0, 0, 0, 0.036)',
  '0px 3px 1px -2px rgba(0, 0, 0, 0.06),0px 2px 2px 0px rgba(0, 0, 0, 0.042),0px 1px 5px 0px rgba(0, 0, 0, 0.036)',
  '0px 3px 3px -2px rgba(0, 0, 0, 0.06),0px 3px 4px 0px rgba(0, 0, 0, 0.042),0px 1px 8px 0px rgba(0, 0, 0, 0.036)',
  '0px 2px 4px -1px rgba(0, 0, 0, 0.06),0px 4px 5px 0px rgba(0, 0, 0, 0.042),0px 1px 10px 0px rgba(0, 0, 0, 0.036)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 5px 8px 0px rgba(0, 0, 0, 0.042),0px 1px 14px 0px rgba(0, 0, 0, 0.036)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.06),0px 6px 10px 0px rgba(0, 0, 0, 0.042),0px 1px 18px 0px rgba(0, 0, 0, 0.036)',
  '0px 4px 5px -2px rgba(0, 0, 0, 0.06),0px 7px 10px 1px rgba(0, 0, 0, 0.042),0px 2px 16px 1px rgba(0, 0, 0, 0.036)',
  '0px 5px 5px -3px rgba(0, 0, 0, 0.06),0px 8px 10px 1px rgba(0, 0, 0, 0.042),0px 3px 14px 2px rgba(0, 0, 0, 0.036)',
  '0px 5px 6px -3px rgba(0, 0, 0, 0.06),0px 9px 12px 1px rgba(0, 0, 0, 0.042),0px 3px 16px 2px rgba(0, 0, 0, 0.036)',
  '0px 6px 6px -3px rgba(0, 0, 0, 0.06),0px 10px 14px 1px rgba(0, 0, 0, 0.042),0px 4px 18px 3px rgba(0, 0, 0, 0.036)',
  '0px 6px 7px -4px rgba(0, 0, 0, 0.06),0px 11px 15px 1px rgba(0, 0, 0, 0.042),0px 4px 20px 3px rgba(0, 0, 0, 0.036)',
  '0px 7px 8px -4px rgba(0, 0, 0, 0.06),0px 12px 17px 2px rgba(0, 0, 0, 0.042),0px 5px 22px 4px rgba(0, 0, 0, 0.036)',
  '0px 7px 8px -4px rgba(0, 0, 0, 0.06),0px 13px 19px 2px rgba(0, 0, 0, 0.042),0px 5px 24px 4px rgba(0, 0, 0, 0.036)',
  '0px 7px 9px -4px rgba(0, 0, 0, 0.06),0px 14px 21px 2px rgba(0, 0, 0, 0.042),0px 5px 26px 4px rgba(0, 0, 0, 0.036)',
  '0px 8px 9px -5px rgba(0, 0, 0, 0.06),0px 15px 22px 2px rgba(0, 0, 0, 0.042),0px 6px 28px 5px rgba(0, 0, 0, 0.036)',
  '0px 8px 10px -5px rgba(0, 0, 0, 0.06),0px 16px 24px 2px rgba(0, 0, 0, 0.042),0px 6px 30px 5px rgba(0, 0, 0, 0.036)',
  '0px 8px 11px -5px rgba(0, 0, 0, 0.06),0px 17px 26px 2px rgba(0, 0, 0, 0.042),0px 6px 32px 5px rgba(0, 0, 0, 0.036)',
  '0px 9px 11px -5px rgba(0, 0, 0, 0.06),0px 18px 28px 2px rgba(0, 0, 0, 0.042),0px 7px 34px 6px rgba(0, 0, 0, 0.036)',
  '0px 9px 12px -6px rgba(0, 0, 0, 0.06),0px 19px 29px 2px rgba(0, 0, 0, 0.042),0px 7px 36px 6px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.06),0px 20px 31px 3px rgba(0, 0, 0, 0.042),0px 8px 38px 7px rgba(0, 0, 0, 0.036)',
]

const themeOptions = {}

themeOptions.default = {
  typography: {
    fontSize: 14,
    body1: {
      fontSize: '14px',
    },
  },

  status: {
    danger: red[500],
  },
  shadows,
  overrides: {
    MuiTable: {
      root: {
        tableLayout: 'fixed',
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '13px',
        padding: '12px 0px',
      },
      root: {
        fontSize: '14px',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        padding: '12px 8px 12px 0px',
      },
    },

    MUIDataTableSelectCell: {
      root: {
        paddingLeft: 12,
      },
    },
    MUIDataTableHeadCell: {
      root: {
        paddingLeft: 16,
      },
    },
    MUIDataTableBodyCell: {
      root: {
        paddingLeft: 8,
      },
    },

    MuiAccordion: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },

    MuiButton: {
      root: {
        fontSize: '14px',
        textTransform: 'none',
        fontWeight: '400',
      },
      contained: {
        boxShadow: shadows[8],
      },
    },
    MuiFab: {
      root: {
        boxShadow: shadows[12],
      },
    },
    MuiCard: {
      root: {
        borderRadius: '8px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '11px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.875rem',
      },
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none',
        },
      },
    },
  },
}

themeOptions[Theme.ACTION] = {
  rootRoutes: {
    [USER_TYPE.LEAD]: null,
    [USER_TYPE.TOTEM]: '/workouts',
    [USER_TYPE.ASSOCIATE]: '/dashboard',
    [USER_TYPE.RELATIVE]: '/dashboard',
    [USER_TYPE.RESPONSIBLE]: '/dashboard',
    [USER_TYPE.MEMBER]: '/dashboard',
    [USER_TYPE.COLLABORATOR]: '/dashboard',
    [USER_TYPE.BIOMETRY_MANAGER]: null,
    [USER_TYPE.MANAGER]: '/dashboard',
    [USER_TYPE.ADMINISTRATOR]: '/dashboard',
  },
  logo: '/assets/images/logos/action.png',
  loginBg:
    'https://images.unsplash.com/photo-1534438327276-14e5300c3a48?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Z3ltfGVufDB8fDB8fA%3D%3D&w=1000&q=80',
  iosPwaInstallationHelpVideo:
    'https://hall-storage.nyc3.digitaloceanspaces.com/ios_pwa_install_action.mp4',
  logoSymbol: '/assets/images/logos/action-white-symbol.svg',
  title: 'Action',
}

themeOptions[Theme.AMSM] = {
  rootRoutes: {
    [USER_TYPE.LEAD]: null,
    [USER_TYPE.TOTEM]: '/workouts',
    [USER_TYPE.ASSOCIATE]: '/dashboard',
    [USER_TYPE.RELATIVE]: '/dashboard',
    [USER_TYPE.RESPONSIBLE]: '/dashboard',
    [USER_TYPE.MEMBER]: '/dashboard',
    [USER_TYPE.COLLABORATOR]: '/dashboard',
    [USER_TYPE.BIOMETRY_MANAGER]: '/biometry-enroll',
    [USER_TYPE.MANAGER]: '/dashboard/default',
    [USER_TYPE.ADMINISTRATOR]: '/dashboard/default',
  },
  logo: '/assets/images/logos/amsm.png',
  loginBg: '/assets/images/backgrounds/amsm.jpg',
  iosPwaInstallationHelpVideo:
    'https://hall-storage.nyc3.digitaloceanspaces.com/ios_pwa_install_amsm.mp4',
  logoSymbol: '/assets/images/logos/amsm.png',
  title: 'AMSM',
}

themeOptions[Theme.CIC] = {
  rootRoutes: {
    [USER_TYPE.LEAD]: null,
    [USER_TYPE.TOTEM]: '/dashboard',
    [USER_TYPE.ASSOCIATE]: '/dashboard',
    [USER_TYPE.RELATIVE]: '/dashboard',
    [USER_TYPE.RESPONSIBLE]: '/dashboard',
    [USER_TYPE.MEMBER]: '/dashboard',
    [USER_TYPE.COLLABORATOR]: '/dashboard',
    [USER_TYPE.BIOMETRY_MANAGER]: '/dashboard',
    [USER_TYPE.MANAGER]: '/dashboard',
    [USER_TYPE.ADMINISTRATOR]: '/dashboard',
  },
  logo: '/assets/images/logos/cic.jpg',
  loginBg:
    'http://media.saomarcos.rs.gov.br/tourism/images/df81d21f-b5ef-4213-a0ae-e9e31405f1e0.jpg',
  // iosPwaInstallationHelpVideo:
  //   'https://hall-storage.nyc3.digitaloceanspaces.com/ios_pwa_install_amsm.mp4',
  logoSymbol: '/assets/images/logos/cic-white.png',
  title: 'CIC São Marcos',
}

themeOptions[Theme.CLAB] = {
  rootRoutes: {
    [USER_TYPE.LEAD]: null,
    [USER_TYPE.TOTEM]: '/dashboard',
    [USER_TYPE.ASSOCIATE]: '/dashboard',
    [USER_TYPE.RELATIVE]: '/dashboard',
    [USER_TYPE.RESPONSIBLE]: '/dashboard',
    [USER_TYPE.MEMBER]: '/dashboard',
    [USER_TYPE.COLLABORATOR]: '/dashboard',
    [USER_TYPE.BIOMETRY_MANAGER]: '/dashboard',
    [USER_TYPE.MANAGER]: '/dashboard',
    [USER_TYPE.ADMINISTRATOR]: '/dashboard',
  },
  logo: '/assets/images/logos/clab-white.svg',
  loginBg:
    'https://plus.unsplash.com/premium_photo-1663040543387-cb7c78c4f012?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  // iosPwaInstallationHelpVideo:
  //   'https://hall-storage.nyc3.digitaloceanspaces.com/ios_pwa_install_amsm.mp4',
  logoSymbol: '/assets/images/logos/clab.svg',
  title: 'Concordia Labs',
}

themeOptions[Theme.IGORSANDI] = {
  rootRoutes: {
    [USER_TYPE.LEAD]: null,
    [USER_TYPE.TOTEM]: '/workouts',
    [USER_TYPE.ASSOCIATE]: '/gym',
    [USER_TYPE.RELATIVE]: '/gym',
    [USER_TYPE.RESPONSIBLE]: '/gym',
    [USER_TYPE.MEMBER]: '/gym',
    [USER_TYPE.COLLABORATOR]: '/gym',
    [USER_TYPE.BIOMETRY_MANAGER]: null,
    [USER_TYPE.MANAGER]: '/gym',
    [USER_TYPE.ADMINISTRATOR]: '/gym',
  },
  logo: '/assets/images/logos/igorsandi.png',
  loginBg:
    'https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80',
  logoSymbol: null, // TODO: Set to white logo
  title: 'Igor Sandi',
}

themeOptions[Theme.GUILHERMELAZARETTI] = {
  rootRoutes: {
    [USER_TYPE.LEAD]: null,
    [USER_TYPE.TOTEM]: '/workouts',
    [USER_TYPE.ASSOCIATE]: '/gym',
    [USER_TYPE.RELATIVE]: '/gym',
    [USER_TYPE.RESPONSIBLE]: '/gym',
    [USER_TYPE.MEMBER]: '/gym',
    [USER_TYPE.COLLABORATOR]: '/dashboard',
    [USER_TYPE.BIOMETRY_MANAGER]: null,
    [USER_TYPE.MANAGER]: '/dashboard',
    [USER_TYPE.ADMINISTRATOR]: '/dashboard',
  },
  logo: '/assets/images/logos/guilhermelazaretti-black-full.png',
  logoSymbol: '/assets/images/logos/guilhermelazaretti-white.png',
  logoSymbolTopNav: '/assets/images/logos/guilhermelazaretti-black.png',
  loginBg:
    'https://images.unsplash.com/photo-1520948013839-62020f374478?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  title: 'Guilherme Lazaretti',
}

themeOptions[Theme.LARISSACIOATO] = {
  rootRoutes: {
    [USER_TYPE.LEAD]: null,
    [USER_TYPE.TOTEM]: '/workouts',
    [USER_TYPE.ASSOCIATE]: '/gym',
    [USER_TYPE.RELATIVE]: '/gym',
    [USER_TYPE.RESPONSIBLE]: '/gym',
    [USER_TYPE.MEMBER]: '/gym',
    [USER_TYPE.COLLABORATOR]: '/gym',
    [USER_TYPE.BIOMETRY_MANAGER]: null,
    [USER_TYPE.MANAGER]: '/gym',
    [USER_TYPE.ADMINISTRATOR]: '/gym',
  },
  logo: '/assets/images/logos/larissacioato-white.png',
  logoSymbol: '/assets/images/logos/larissacioato.png',
  logoSymbolTopNav: '/assets/images/logos/larissacioato.png',
  loginBg:
    'https://images.unsplash.com/photo-1541534741688-6078c6bfb5c5?q=80&w=3569&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  title: 'Larissa Cioato',
}

themeOptions[Theme.BIOACADEMIA] = {
  rootRoutes: {
    [USER_TYPE.LEAD]: null,
    [USER_TYPE.TOTEM]: null,
    [USER_TYPE.ASSOCIATE]: null,
    [USER_TYPE.RELATIVE]: null,
    [USER_TYPE.RESPONSIBLE]: null,
    [USER_TYPE.MEMBER]: null,
    [USER_TYPE.COLLABORATOR]: '/user/list',
    [USER_TYPE.BIOMETRY_MANAGER]: null,
    [USER_TYPE.MANAGER]: '/user/list',
    [USER_TYPE.ADMINISTRATOR]: '/finances',
  },
  logo: '/assets/images/logos/bioacademia.jpeg',
  loginBg:
    'https://images.unsplash.com/photo-1541534741688-6078c6bfb5c5?q=80&w=2969&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  logoSymbol: null, // TODO: Set to white logo
  title: 'BioAcademia',
}

themeOptions[Theme.DCFIT] = {
  rootRoutes: {
    [USER_TYPE.LEAD]: null,
    [USER_TYPE.TOTEM]: null,
    [USER_TYPE.ASSOCIATE]: null,
    [USER_TYPE.RELATIVE]: null,
    [USER_TYPE.RESPONSIBLE]: null,
    [USER_TYPE.MEMBER]: '/gym',
    [USER_TYPE.COLLABORATOR]: '/gym',
    [USER_TYPE.BIOMETRY_MANAGER]: null,
    [USER_TYPE.MANAGER]: '/gym',
    [USER_TYPE.ADMINISTRATOR]: '/gym',
  },
  logo: '/assets/images/logos/dcfit-login.jpg',
  loginBg:
    'https://images.unsplash.com/photo-1526409499567-9f0af1fcb6d1?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  logoSymbol: null, // TODO: Set to white logo
  title: 'DCFit',
}
export default themeOptions

import { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import HttpHandler, { getModelChangeEvent } from 'app/reusable/HttpHandler'
import { toast } from 'react-toastify'
import FormContext from 'app/contexts/FormContext'
import useEmbbededRecords, { clearFormData } from './useEmbeddedForm'
import { useDispatch } from 'react-redux'
import { useSavedFormState } from 'app/ducks/formState/hooks'
import { formStateOperations } from 'app/ducks/formState'

const useDjangoForm = ({
  djangoApp,
  djangoModel,
  id,
  predefinedData,
  hideOnUpdate,
  initialData,
  onChange,
  currentStep,
}) => {
  const dispatch = useDispatch()
  const { data: formInitial } = useSavedFormState(djangoApp, djangoModel)

  const embeddedRecords = useEmbbededRecords({
    rootApp: djangoApp,
    rootModel: djangoModel,
    rootId: id,
  })

  const [form, setForm] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (currentStep > 0)
      dispatch(
        formStateOperations.saveState(
          djangoApp,
          djangoModel,
          form?.data,
          embeddedRecords.records,
          currentStep
        )
      )
  }, [
    form,
    currentStep,
    dispatch,
    djangoApp,
    djangoModel,
    embeddedRecords.records,
  ])

  const fields = useMemo(() => {
    if (form?.fields) {
      const newFields = { ...form.fields }

      if (predefinedData) {
        for (const key in newFields) {
          if (predefinedData[key] !== undefined) delete newFields[key]
        }
      }

      if (hideOnUpdate) {
        for (const key in newFields) {
          if (hideOnUpdate.includes(key)) delete newFields[key]
        }
      }

      return newFields
    }
    return []
    // eslint-disable-next-line
  }, [form])

  useEffect(() => {
    setLoading(true)
    HttpHandler.form(djangoApp, djangoModel, id)
      .then(({ data: { form } }) => {
        setForm({
          ...form,
          data: {
            ...form.data,
            ...(initialData || {}),
            ...(predefinedData || {}),
            ...formInitial,
          },
        })
      })
      .catch(() => toast.error('Não foi possível buscar o formulário'))
      .finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [djangoApp, djangoModel, id])

  useEffect(() => onChange && onChange(form?.data), [form, onChange])

  const handleFieldChange = useCallback(
    ({ target: { value, name } }) =>
      setForm((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          [name]: value,
        },
      })),
    [setForm]
  )

  const handleRawFieldChange = useCallback(
    (name, value) =>
      setForm((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          [name]: value,
        },
      })),
    [setForm]
  )

  const updateData = useCallback(
    (newData) =>
      setForm((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          ...newData,
        },
      })),
    [setForm]
  )

  const handleSubmit = useCallback(
    (successCallback = () => {}, persistIdToForm = false) => {
      setLoading(true)
      const possibleId = id || form?.data?.id
      if (possibleId)
        HttpHandler.update(
          djangoApp,
          djangoModel,
          clearFormData(form.data),
          possibleId
        )
          .then(({ data: { form } }) => {
            if (form) setForm(form)
            else {
              toast.success('Sucesso ao editar registro!')
              successCallback()
            }
          })
          .finally(() => {
            dispatch(
              formStateOperations.clearModelState(djangoApp, djangoModel)
            )
            window.dispatchEvent(getModelChangeEvent(djangoApp, djangoModel))
            setLoading(false)
          })
      else
        HttpHandler.create(djangoApp, djangoModel, clearFormData(form.data))
          .then(async ({ data: { form, pk } }) => {
            embeddedRecords.setRootId(pk)
            if (form) {
              setForm(form)
              if (form.errors.__all__)
                for (const err of form.errors.__all__) {
                  toast.warn(err)
                }
            } else {
              toast.success('Sucesso ao criar registro!')
              if (persistIdToForm)
                setForm((prev) => ({ ...prev, data: { ...prev.data, id: pk } }))
              if (embeddedRecords.records?.length)
                await toast
                  .promise(embeddedRecords.submit(pk), {
                    pending: 'Salvando registros relacionados...',
                    success: 'Registros relacionados salvos!',
                    error: 'Falha ao salvar registros relacionados.',
                  })
                  .then(console.log)
              successCallback({ pk })
            }
          })
          .finally(() => {
            dispatch(
              formStateOperations.clearModelState(djangoApp, djangoModel)
            )
            window.dispatchEvent(getModelChangeEvent(djangoApp, djangoModel))
            setLoading(false)
          })
    },
    [form, setForm, djangoApp, djangoModel, id, embeddedRecords, dispatch]
  )

  const clearForm = useCallback(() => {
    setForm((f) => ({
      ...f,
      data: {},
    }))
  }, [setForm])

  const allRequiredFieldsFilled = useMemo(
    () =>
      Object.entries(form?.fields || {}).every(([field, { required, title }]) =>
        required && title !== 'ModelChoiceField' ? form?.data[field] : true
      ),
    [form]
  )

  return {
    ...form,
    fields,
    appName: djangoApp,
    modelName: djangoModel,
    id: id || form?.data?.id,
    allRequiredFieldsFilled,
    loading,
    handleFieldChange,
    handleRawFieldChange,
    handleSubmit,
    updateData,
    clearForm,
    embeddedRecords,
  }
}

export default useDjangoForm

export const useDjangoFormContext = () => {
  return useContext(FormContext)
}

import * as types from './types'

export const showModal =
  ({
    title,
    image,
    children,
    onConfirm,
    confirmLabel,
    onCancel,
    cancelLabel,
    onClickOutside,
    disableBackdropClick,
    persistent,
    id,
  }) =>
  (dispatch) => {
    dispatch({
      type: types.SHOW,
      payload: {
        title,
        image,
        children,
        onConfirm,
        confirmLabel,
        onCancel:
          onCancel ?? (onCancel !== null && (() => dismissModal()(dispatch))),
        cancelLabel,
        onClickOutside: onClickOutside ?? (() => dismissModal()(dispatch)),
        disableBackdropClick,
        persistent,
        id,
      },
    })
  }

export const showModalForm =
  ({
    id,
    app,
    model,
    onSubmit,
    predefinedData,
    onSuccess,
    initialData,
    hideOnUpdate,
    disableBackdropClick,
  }) =>
  (dispatch) => {
    dispatch({
      type: types.SHOW,
      payload: {
        id,
        app,
        model,
        onSubmit,
        predefinedData,
        hideOnUpdate,
        initialData,
        onSuccess,
        disableBackdropClick,
      },
    })
  }

export const persistModalFormValues = (data) => (dispatch) => {
  dispatch({
    type: types.PERSIST,
    payload: data,
  })
}

export const showMessagingModal = () => (dispatch) => {
  dispatch({
    type: types.SHOW,
    payload: {
      messaging: true,
    },
  })
}

export const dismissModal = () => (dispatch) =>
  dispatch({ type: types.DISMISS })

export const setModalState = (payload) => (dispatch) =>
  dispatch({ type: types.SET_STATE, payload })

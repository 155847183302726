import React from 'react'
import { useDismissModal, useModal } from 'app/ducks/modal/hooks'
import HttpHandler from 'app/reusable/HttpHandler'
import { toast } from 'react-toastify'

const useDeleteRecord = () => {
  const showModal = useModal()
  const dismissModal = useDismissModal()

  const onDelete = ({ app, model, id, description, onSuccess }) => {
    showModal({
      title: 'Excluir registro',
      children: (
        <p>
          Deseja excluir o registro <b>{description}</b>? Essa ação não poderá
          ser desfeita.
        </p>
      ),
      confirmLabel: 'Excluir',
      onConfirm: () => {
        dismissModal()
        toast
          .promise(HttpHandler.delete(app, model, id), {
            pending: 'Excluíndo registro...',
            success: 'Registro excluído!',
            error: 'Erro ao excluir o registro.',
          })
          .then(() => {
            if (onSuccess) onSuccess()
          })
      },
    })
  }
  return onDelete
}

export default useDeleteRecord

import {
  dashboard,
  userList,
  benefits,
  gym,
  schools,
  findPhysicalSpaces,
  tickets,
  workouts,
  finances,
  calendar,
  associationTitles,
  healthInsuranceComparisonReport,
  biometryEnroll,
  physicalSpaceReservations,
  physicalAccess,
  customReport,
  contracts,
  campaigns,
  spaceRenting,
  events,
  accountsPayablePage,
} from 'app/views/routes'
import { USER_TYPE } from './ducks/auth/types'
import { Theme } from './components/MatxTheme/themes'

export const navigations = [
  dashboard,
  {
    name: 'Administrativo',
    icon: 'admin_panel_settings',
    roles: [USER_TYPE.ADMINISTRATOR],
    allowedOnThemes: [Theme.AMSM, Theme.CIC, Theme.CLAB, Theme.BIOACADEMIA],
    children: [
      {
        name: 'Administração',
        path: `${process.env.REACT_APP_BACKEND_URL}/admin`,
        type: 'extLink',
        icon: 'admin_panel_settings',
        roles: [USER_TYPE.ADMINISTRATOR],
        allowedOnThemes: [Theme.AMSM, Theme.CLAB],
      },
      finances,
      accountsPayablePage,
      // physicalDeviceManagement,
      associationTitles,
      healthInsuranceComparisonReport,
    ],
  },
  userList,
  {
    ...workouts,
    name: 'Treinos',
    roles: [USER_TYPE.TOTEM],
  },
  gym,
  contracts,
  campaigns,
  events,
  spaceRenting,
  schools,
  biometryEnroll,
  tickets,
  physicalAccess,
  calendar,
  benefits,
  customReport,
  findPhysicalSpaces,
  physicalSpaceReservations,
]

export const Theme = {
  ACTION: 'action',
  AMSM: 'amsm',
  CIC: 'cic',
  CLAB: 'clab',
  IGORSANDI: 'igorsandi',
  GUILHERMELAZARETTI: 'guilhermelazaretti',
  LARISSACIOATO: 'larissacioato',
  BIOACADEMIA: 'bioacademia',
  DCFIT: 'dcfit',
}

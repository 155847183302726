import React, { useEffect, useMemo, useState } from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Icon,
  ListSubheader,
  TextField,
  FormHelperText,
} from '@material-ui/core'
import { appendRequired } from 'app/reusable/utils'
import { Grid } from '@material-ui/core'
import { useModalForm } from 'app/ducks/modal/hooks'
import useRequest from 'app/hooks/useRequest'
import HttpHandler from 'app/reusable/HttpHandler'
import { useHasPermission } from 'app/ducks/auth/hooks'
import debounce from 'lodash/debounce'
import { Loading } from 'app/components/SimpleList/SimpleList'

const UpdateButton = ({ djangoApp, djangoModel, id }) => {
  const showModalForm = useModalForm()
  const hasPermission = useHasPermission(djangoModel, 'change')

  if (!id || !djangoModel || !hasPermission) return null
  return (
    <Grid item>
      <Button
        className={`px-1 t-create-${djangoApp}-${djangoModel}`}
        color="primary"
        variant="outlined"
        onClick={() =>
          showModalForm({
            app: djangoApp,
            model: djangoModel,
            id,
          })
        }
      >
        <Icon>create</Icon>
      </Button>
    </Grid>
  )
}

const CreateButton = ({ djangoApp, djangoModel }) => {
  const showModalForm = useModalForm()
  const hasPermission = useHasPermission(djangoModel, 'add')

  if (!djangoModel || !hasPermission) return null
  return (
    <Grid item>
      <Button
        className={`px-1 t-add-${djangoApp}-${djangoModel}`}
        color="primary"
        variant="outlined"
        onClick={() =>
          showModalForm({
            app: djangoApp,
            model: djangoModel,
          })
        }
      >
        <Icon>add</Icon>
      </Button>
    </Grid>
  )
}

export const SearchField = ({ onChange }) => {
  const debouncedChange = useMemo(() => debounce(onChange, 300), [onChange])
  return (
    <ListSubheader className="my-2" style={{ lineHeight: 1 }}>
      <TextField
        autoFocus
        fullWidth
        size="small"
        placeholder="Digite para pesquisar..."
        onChange={(e) => debouncedChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key !== 'Escape') e.stopPropagation()
        }}
      />
    </ListSubheader>
  )
}

const SelectField = ({
  field,
  onChange,
  name,
  value,
  errors,
  testingClassName,
  showUpdateButton = true,
  showCreateButton = true,
  disabled = false,
}) => {
  const { app_name: djangoApp, model_name: djangoModel } = field

  const choicesRequest = useRequest(HttpHandler.list)

  const [searchText, setSearchText] = useState()
  const isModel = Boolean(djangoApp && djangoModel)
  const choices = isModel
    ? choicesRequest.response?.data?.data
    : field.widget.choices

  useEffect(() => {
    if (djangoApp && djangoModel)
      if (value && !searchText)
        choicesRequest.send(djangoApp, djangoModel, {
          id: value,
          select_return: true,
          paginate_by: value.length || 1,
        })
      else
        choicesRequest.send(djangoApp, djangoModel, {
          text_filter: searchText,
          select_return: true,
          paginate_by:
            {
              'financial-event': 999,
            }[djangoModel] || 5,
        })
    // eslint-disable-next-line
  }, [searchText, djangoApp, djangoModel])

  if (isModel && choicesRequest.isLoading && !searchText)
    return (
      <FormControl
        label={field.label}
        size="small"
        fullWidth
        variant="outlined"
      >
        <InputLabel margin="dense">
          {appendRequired(field.label, field.required)}
        </InputLabel>
        <Loading size={20} />
      </FormControl>
    )
  return (
    <FormControl label={field.label} size="small" fullWidth variant="outlined">
      <InputLabel margin="dense">
        {appendRequired(field.label, field.required)}
      </InputLabel>
      <Grid container spacing={1}>
        <Grid item xs>
          <Select
            name={name}
            label={field.label}
            className={testingClassName}
            data-djangoapp={djangoApp}
            data-djangomodel={djangoModel}
            multiple={field.widget.input_type === 'selectmultiple'}
            MenuProps={{ autoFocus: false }}
            disabled={disabled}
            // renderValue={() => selectedOption}
            onChange={(e) => {
              // TODO: Fix that for select multiple
              onChange(e)
              const selectedChoice = choices.find(
                ({ value }) => value === e.target.value
              )
              console.log(selectedChoice)
              onChange({
                target: {
                  name: `${name}__label`,
                  value: selectedChoice?.label || selectedChoice?.display,
                  valueId: selectedChoice?.value,
                },
              })
            }}
            value={
              field.widget.input_type === 'selectmultiple'
                ? value || []
                : value || undefined
            }
            fullWidth
          >
            {isModel && <SearchField onChange={setSearchText} />}
            {choices?.map(({ value, label, display }) => (
              <MenuItem key={value} value={value}>
                {label || display}
              </MenuItem>
            ))}
            {!choicesRequest.isLoading && choices?.length === 0 && (
              <p className="my-2 mt-5 mx-3">Nenhuma opção encontrada</p>
            )}
          </Select>
        </Grid>
        {showUpdateButton && (
          <UpdateButton
            id={value}
            djangoApp={djangoApp}
            djangoModel={djangoModel}
          />
        )}
        {showCreateButton && (
          <CreateButton djangoApp={djangoApp} djangoModel={djangoModel} />
        )}
      </Grid>
      {!!errors && (
        <small className="mb-2 mt-1 ml-1 text-error">{errors.join(', ')}</small>
      )}
      {!!field.help_text && (
        <FormHelperText className="mb-3">{field.help_text}</FormHelperText>
      )}
    </FormControl>
  )
}

export default SelectField

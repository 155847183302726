import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Button, CircularProgress, Divider, Icon } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import useRequest from 'app/hooks/useRequest'
import HttpHandler, { getModelChangeEvent } from 'app/reusable/HttpHandler'
import { useHasPermission } from 'app/ducks/auth/hooks'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import Pagination from '@mui/material/Pagination'
import { debounce } from 'lodash'
import { useModalForm } from 'app/ducks/modal/hooks'
import FrontendAggregation from './FrontendAggregation'

export const Loading = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{
      padding: '1rem',
      display: 'block',
      width: '100%',
      textAlign: 'center',
    }}
  >
    <CircularProgress size={props.size || 40} />
  </div>
))

const DefaultWrapper = ({ isLoading, children }) => {
  if (isLoading) return <Loading />
  return children
}

const PaginationWrapper = ({ paginator, isLoading, setPage, children }) => {
  if (isLoading) return <Loading />
  return (
    <>
      {children}
      {paginator?.last_page_number > 1 && (
        <Pagination
          showLastButton
          showFirstButton
          boundaryCount={0}
          siblingCount={1}
          page={paginator.current_page_number}
          count={paginator.last_page_number}
          onChange={(_, page) => setPage(page)}
          className="mt-5"
        />
      )}
    </>
  )
}

const InfiniteScrollWrapper = ({
  paginator,
  error,
  isLoading,
  paginateBy,
  setPaginateBy,
  children,
}) => {
  const hasNextPage =
    paginator && paginator.last_page_number > paginator.current_page_number
  const debouncedLoad = useCallback(
    debounce(
      () => {
        if (!isLoading) setPaginateBy(paginateBy + 10)
      },
      1000,
      { trailing: true }
    )
  )
  const [loaderRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasNextPage,
    onLoadMore: debouncedLoad,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    // rootMargin: '0px 0px 400px 0px',
  })
  const showLoading = isLoading || hasNextPage
  return (
    <>
      {children}
      {showLoading && <Loading ref={loaderRef} />}
    </>
  )
}

export const RawItemsList = ({
  items,
  refreshList,
  itemOverwrites,
  itemComponent: ItemComponent,
}) =>
  items?.map((item, idx) => {
    if (itemOverwrites && item.id) {
      const overwrites = itemOverwrites[item.id]
      if (overwrites) Object.assign(item, overwrites)
    }
    return (
      <ItemComponent
        key={idx}
        itemIdx={idx}
        refreshList={refreshList}
        {...item}
      />
    )
  }) || null

const SimpleList = forwardRef(
  (
    {
      djangoApp,
      djangoModel,
      permissionsDjangoModel,
      title,
      subtitle,
      emptyStateIcon,
      emptyStateText,
      titleRowButtonProps,
      search,
      paginateBy: initialPaginateBy = 10,
      itemComponent,
      infiniteScroll,
      showPagination,
      hiddenWhenEmpty,
      useApiTitle,
      itemOverwrites = null, // NOTE: A key/value object where the key is the record pk and the value as an object of overwrites for the given pk
      showTotal = false,
      searchable = false,
      showRefresh = false,
      appendDivider = false,
      showCreationButton = false,
      creationPredefinedCata = {},
      frontendAggregator = null, // NOTE: Workaround to be able to aggregate data on the frontend side,
      extraComponents, // NOTE: Used to add the clear button to the side of the list
      ...props
    },
    ref
  ) => {
    const showModalForm = useModalForm()
    const [page, setPage] = useState(1)
    const [textFilter, setTextFilter] = useState(undefined)
    const [paginateBy, setPaginateBy] = useState(initialPaginateBy)

    const hasViewPermission = useHasPermission(
      permissionsDjangoModel || djangoModel,
      'view'
    )
    const listRequest = useRequest(
      HttpHandler.list,
      `${djangoApp}-${djangoModel}-list`,
      hasViewPermission
        ? [
            djangoApp,
            djangoModel,
            {
              ...(search || {}),
              paginate_by: paginateBy,
              format: 'json',
            },
          ]
        : undefined
    )
    const { data: items, paginator } = listRequest.response?.data || {}

    const sendRequest = useCallback(
      () => {
        const params = {
          ...(search || {}),
          page,
          paginate_by: paginateBy,
          format: 'json',
        }
        if (searchable) {
          params.text_filter = textFilter
        }
        listRequest.send(djangoApp, djangoModel, params)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [search, searchable, textFilter, page, paginateBy]
    )

    useEffect(() => {
      const modelChangeEvent = getModelChangeEvent(djangoApp, djangoModel)

      const modelChangeListener = () => {
        sendRequest()
      }
      window.addEventListener(modelChangeEvent.type, modelChangeListener)
      return () =>
        window.removeEventListener(modelChangeEvent.type, modelChangeListener)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      sendRequest()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, textFilter, page, paginateBy])

    useImperativeHandle(ref, () => {
      return {
        reload: sendRequest,
        textFilter,
      }
    })

    const Wrapper = useMemo(() => {
      if (showPagination) return PaginationWrapper
      else if (infiniteScroll) return InfiniteScrollWrapper
      return DefaultWrapper
    }, [showPagination, infiniteScroll])

    if (!hasViewPermission) return null
    if (hiddenWhenEmpty && listRequest.response?.data?.data?.length === 0)
      return null

    return (
      <div {...props}>
        <div className="mb-4">
          <div className="flex-center-v flex-between">
            <div>
              {useApiTitle && listRequest.response?.data?.title && (
                <h4 className="mb-0">
                  {listRequest.response?.data?.title.toTitleCase()}
                </h4>
              )}
              {!useApiTitle && title && <h4 className="mb-0">{title}</h4>}
              {!!subtitle && <p className="mt-1 mb-2">{subtitle}</p>}
            </div>
            {!!titleRowButtonProps && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                {...titleRowButtonProps}
              />
            )}
            {!!showRefresh && (
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => {
                  HttpHandler.clearMemos()
                  sendRequest()
                }}
              >
                Atualizar
              </Button>
            )}
          </div>
          {showTotal && items && <small>{items.length} registros</small>}
          {frontendAggregator && (
            <FrontendAggregation
              djangoApp={djangoApp}
              djangoModel={djangoModel}
              search={search}
              aggregator={frontendAggregator}
            />
          )}
        </div>
        {!!searchable && (
          <TextField
            value={textFilter}
            className="w-full text-center mb-3"
            placeholder="Digite para pesquisar"
            onChange={(e) => {
              const value = e.target.value
              setTextFilter(value)
            }}
          />
        )}
        <Wrapper
          paginator={paginator}
          error={listRequest.error}
          isLoading={listRequest.isLoading}
          setPage={setPage}
          paginateBy={paginateBy}
          setPaginateBy={setPaginateBy}
        >
          {!listRequest.isLoading &&
            items?.length === 0 &&
            (emptyStateIcon ? (
              <div className="text-center p-5" style={{ opacity: 0.3 }}>
                <Icon>{emptyStateIcon}</Icon>
                <p>{emptyStateText}</p>
              </div>
            ) : (
              emptyStateText
            ))}
          <RawItemsList
            items={items}
            refreshList={sendRequest}
            itemOverwrites={itemOverwrites}
            itemComponent={itemComponent}
          />
          {extraComponents}
        </Wrapper>
        {showCreationButton && (
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() =>
              showModalForm({
                app: djangoApp,
                model: djangoModel,
                predefinedData: creationPredefinedCata,
              })
            }
          >
            Incluir novo
          </Button>
        )}
        {appendDivider && <Divider className="my-5" />}
      </div>
    )
  }
)

export default SimpleList

import useRequest from './useRequest'
import HttpHandler from 'app/reusable/HttpHandler'
import { useEffect } from 'react'

const useSignedUrl = (url) => {
  const signedUrlRequest = useRequest(HttpHandler.getSignedMediaUrl)
  useEffect(() => {
    if (url) signedUrlRequest.send(url)
  }, [url, signedUrlRequest])
  return signedUrlRequest?.response?.data.signed_url
}

export default useSignedUrl

import React from 'react'
import { FormHelperText, TextField } from '@material-ui/core'
import { appendRequired } from 'app/reusable/utils'

const parseNumber = (v) => {
  const strNumber = String(v)
  const cleanNumber = +strNumber.replace(/[^\d]/g, '')
  return strNumber.startsWith('-') ? cleanNumber * -1 : cleanNumber
}

const fieldParsers = {
  IntegerField: {
    onChange: (v) => parseNumber(v).toFixed(0),
    value: (v) => parseNumber(v).toFixed(0),
  },
  FloatField: {
    onChange: (v) => parseNumber(v) / 100,
    value: (v) => (+v).toFixed(2),
  },
}

const NumberInput = ({
  name,
  value,
  field,
  onChange,
  errors,
  testingClassName,
}) => (
  <TextField
    helperText={
      !!(errors || field.help_text) && (
        <FormHelperText className="mb-3">
          {errors ? errors.join(', ') : field.help_text}
        </FormHelperText>
      )
    }
    name={name}
    onChange={(e) => {
      onChange({
        target: {
          name: e.target.name,
          value: fieldParsers[field.title].onChange(e.target.value),
        },
      })
    }}
    error={errors?.length}
    className={testingClassName}
    fullWidth
    variant="outlined"
    label={appendRequired(field.label, field.required)}
    value={fieldParsers[field.title].value(value || 0)}
    size="small"
    inputProps={{
      maxLength: field.max_length,
    }}
  />
)

export default NumberInput
